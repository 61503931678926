@if (isGalleryLoaded) {
    <app-image-gallery [slug]="slug" [type]="routesConstants.VENUES" [imageAltTag]="venueDetails.venueName"
                       (backToParent)="toggleGallery(false)"></app-image-gallery>
} @else if (isMapLoeded) {
    <app-venue-map [slug]="slug" (backToParent)="toggeleMap(false)"></app-venue-map>
} @else {
    <section class="breadcrumb-wrap">
        <div class="container">
            <ul>
                <li><a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION]">Venues</a></li>
                <li><img src="assets/images/chevron-right-icon.svg" width="6" height="10" alt="chevron-right-icon"></li>
                <li>{{ venueDetails?.venueName }}</li>
            </ul>
        </div>
    </section>

    <section class="details-head-wrap">
        <div class="container">
            <div class="details-head-info">
                <div class="details-head-info-left">
                    <h1 class="section-title">{{ venueDetails?.venueName }}
                        @if (venueDetails?.isFeatured) {
                            <label class="featured-label">Featured</label>
                        }
                    </h1>
                    <ul>
                        @if (venueDetails?.regions?.name && venueDetails?.states?.name && venueDetails?.countries?.name) {
                            <li><img src="assets/images/location-icon.svg" width="24" height="24"
                                     alt="chevron-right-icon">
                                <span class="location-link">{{ venueDetails?.regions?.name }},
                                    {{ venueDetails?.states?.name }},
                                    {{ venueDetails?.countries?.name }}</span>
                            </li>
                        }
                        @if (venueDetails?.traditionalOwners) {
                            <li><img src="assets/images/place-icon.svg" width="24" height="24"
                                     alt="chevron-right-icon">{{ venueDetails?.traditionalOwners }}
                            </li>
                        }
                    </ul>
                </div>
                <div class="details-head-info-action">
                    <!-- Not in current Phase -->
                    <!-- <button type="button" class="secondary-btn icon-btn">
                      <img src="assets/images/forward-icon.svg" width="20" height="20" alt="forward-icon">
                    </button> -->
                    <button type="button" class="secondary-btn icon-btn" (click)="copyUrl()">
                        <img src="assets/images/link-icon.svg" width="24" height="24" alt="link-icon">
                    </button>
                    <!-- Not in current Phase -->
                    <!-- <button type="button" class="secondary-btn saved" (click)="openVerticallyCentered(signup, 'auth-modal')">
                      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.31004 0.515166H9.31182L9.35104 0.515174L9.35391 0.515158C9.8098 0.512543 10.2615 0.600183 10.6827 0.772835C11.1012 0.944433 11.4809 1.19652 11.8001 1.5143C12.4269 2.18719 12.7913 3.05866 12.829 3.97264L12.8291 3.97531C12.8985 5.46691 12.1053 6.8495 11.0842 7.99484C10.0708 9.13167 8.89601 9.96371 8.33784 10.3481L8.33583 10.3495C7.90343 10.6505 7.54867 10.8774 7.30404 11.0142L7.11155 11.1033L7.02417 11.1322C6.98014 11.146 6.93527 11.1572 6.88982 11.1655C6.85582 11.1674 6.82172 11.167 6.78777 11.1642L6.76714 11.1624H6.74645H6.58168H6.56098L6.54035 11.1642C6.50641 11.167 6.4723 11.1674 6.4383 11.1655C6.39284 11.1572 6.34796 11.146 6.30391 11.1322L6.21657 11.1033L6.02691 11.0155C5.78369 10.8757 5.42075 10.6474 5.00356 10.3519L5.00357 10.3519L4.99911 10.3488C4.4182 9.94668 3.23472 9.11506 2.22177 7.98256C1.2018 6.84221 0.419933 5.46978 0.506599 3.98111L0.506671 3.98112L0.50702 3.97264C0.54469 3.05867 0.909061 2.18723 1.53578 1.51437C2.16481 0.888487 3.01314 0.525302 3.90579 0.501273C4.80122 0.477169 5.67086 0.796254 6.33414 1.39108L6.66783 1.69034L7.00164 1.39122C7.63366 0.824884 8.45677 0.512122 9.31004 0.515166Z"
                          stroke="#272338" />
                      </svg>
                      <span>Saved</span>
                    </button> -->
                    <button type="button" class="primary-btn" (click)="openEnquireVenueModal('venue-enquire')">Enquire
                        with
                        venue
                    </button>
                </div>
            </div>

            <!-- Venue images -->
            <!-- @if(firstImage) { -->
            <div class="details-top-gallery">
                <a [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION]" class="mob-back-to-list">
                    <img src="assets/images/back-circle-icon.svg" width="32" height="32" alt="back-circle-icon">
                </a>

                <button (click)="toggleGallery(true)"
                        class="secondary-btn">
                    <img src="assets/images/image-icon.svg" width="20" height="20" alt="image-icon">
                    @if (moreImageCount > 0) {
                        <span>{{ moreImageCount }} more</span><span class="mob-img-counter">1 /
                            {{ venueDetails?.venue_images?.length }}</span>
                    } @else {
                        <span>View Images</span>
                    }
                </button>

                <div class="details-gallery-left">
                    <div class="details-gallery-img">
                        <img
                            [src]="firstImage?.imagePath ? commonConstants.IMAGE_KIT_URL + firstImage.imagePath : commonConstants.DEFAULT_NO_IMAGE"
                            width="654" height="523" alt={{venueDetails?.venueName}} (error)="setDefaultImage($event)"
                            appSetImageDimensions loading="lazy">
                    </div>
                </div>

                <div class="details-gallery-right">
                    @if (remainingAndDefaultImages && remainingAndDefaultImages.length > 0) {
                        <ul>
                            @for (image of remainingAndDefaultImages; track image; let img = $index) {
                                <li>
                                    <!-- <span class="video-icon"><img src="assets/images/video-icon.svg" alt="video-icon"></span> -->
                                    <div class="details-gallery-img">
                                        <img
                                            [src]="image?.imagePath ? (image?.venueId ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE) : commonConstants.DEFAULT_NO_IMAGE"
                                            width="294" height="253" alt={{venueDetails?.venueName}}
                                            (error)="setDefaultImage($event)" appSetImageDimensions loading="lazy">
                                    </div>
                                </li>
                            }
                        </ul>
                    }
                </div>

            </div>
            <!-- } -->
        </div>
    </section>

    <section class="details-content-wrap">
        <div class="container">
            <div class="details-left-panel">
                @if (venueDetails?.venueHostedBy || venueDetails?.venueName) {
                    <div class="user-review-box">
                        <div class="user-review-info">
                            <img
                                [src]="venueDetails?.listingLogo ? commonConstants.IMAGE_KIT_URL + venueDetails?.listingLogo : commonConstants.DEFAULT_USER_IMAGE"
                                width="126" height="126" alt="profile-image" (error)="setDefaultImage($event)"
                                appSetImageDimensions>
                            Venue hosted by {{
                                venueDetails?.venueHostedBy && venueDetails?.venueHostedBy != '' ?
                                    venueDetails?.venueHostedBy : venueDetails?.venueName
                            }}
                        </div>
                        <!-- Not in current Phase -->
                        <!-- <div class="user-reviews">
                          <img src="assets/images/color-star-icon.svg" width="21" height="22" alt="color-star-icon">
                          4.9
                          <button type="button" class="more-review">145 reviews</button>
                        </div> -->
                    </div>
                }

                @if (venueDetails?.venueDescription) {
                    <div class="details-content-box">
                        <div class="details-section-title">About</div>
                        <div class="details-content-desc" #aboutContainer [class.show-more-desc]="isAboutExpanded"
                             [innerHTML]="venueDetails?.venueDescription | safeHtml"></div>
                        @if (isMoreAboutDesc) {
                            <button class="read-more-btn" [class.read-less]="isAboutExpanded"
                                    (click)="toggleContent()">{{
                                    isAboutExpanded ?
                                        'Tell me less' : 'Tell me more'
                                }}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.04 6.51953V18.2197" stroke="#7093FF" stroke-miterlimit="10"
                                          stroke-linecap="round"/>
                                    <path d="M8.04004 14.2207L12.04 18.2207L16.04 14.2207" stroke="#7093FF"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                            </button>
                        }
                    </div>
                }

                <!-- Not in current Phase -->
                <!-- <div class="pricing-inclusions">
                  <h2 class="details-section-title">Pricing inclusions</h2>
                  <div class="pricing-lock-box">
                    <img src="assets/images/lock-icon.svg" width="32" height="32" alt="lock-icon">
                    <h3 class="details-section-title">Want to view more?</h3>
                    <p>To view pricing breakdowns you will need to either sign in or create an account. We promise it’s worth
                      it!</p>
                    <button type="button" class="primary-btn" (click)="openVerticallyCentered(signup, 'auth-modal')">Sign
                      up</button>
                  </div>

                  <div class="pricing-inclusions-info">
                    <ul>
                      <li><label>Venue hire</label>
                        <p>$6,000</p>
                      </li>
                      <li><label>Minimum spend</label>
                        <p>$6,000</p>
                      </li>
                      <li>
                        <label>Food packages</label>
                        <p>Sit down from $250 - $300<br /> Standing from $150 - $200</p>
                      </li>
                      <li>
                        <label>Beverage packages</label>
                        <p>From $120 - $220<br /> Corkage fee $5 per head</p>
                      </li>
                      <li>
                        <label>Elopements / micro weddings</label>
                        <p>Hire price from $2,000</p>
                      </li>
                    </ul>
                    <p><label>Additional hire items available for a fee</label></p>
                    <p>- Clean up the following day<br /> - Another item</p>
                  </div>

                  <div class="pricing-inclusions-info pricing-inclusions-features">
                    <h3>Venue features</h3>
                    <ul>
                      <li><img src="assets/images/tree-icon.svg" width="24" height="24" alt="tree-icon"> <strong>Ceremony
                          type:</strong> Outdoor</li>
                      <li><img src="assets/images/watch-icon.svg" width="24" height="24" alt="watch-icon"> <strong>Event
                          time:</strong> 4 - 10 hours</li>
                      <li><img src="assets/images/moon-icon.svg" width="24" height="24" alt="moon-icon"> <strong>Curfew:</strong>
                        10:30pm</li>
                      <li><img src="assets/images/person-icon.svg" width="24" height="24" alt="person-icon"> <strong>Min-max
                          guests:</strong> 2 - 400</li>
                      <li><img src="assets/images/money-icon.svg" width="24" height="24" alt="money-icon"> <strong>Price per
                          head:</strong> $145 - $175</li>
                      <li><img src="assets/images/clock-icon.svg" width="24" height="24" alt="clock-icon"> <strong>Additional hire
                          hours:</strong> $20 per head/hour</li>
                      <li><img src="assets/images/food-icon.svg" width="24" height="24" alt="food-icon">
                        <strong>Catering:</strong> In house or third party options
                      </li>
                    </ul>
                  </div>

                  <div class="pricing-inclusions-info">
                    <h3 class="details-section-title">Off peak pricing</h3>
                    <ul>
                      <li><label>Venue hire</label>
                        <p>$6,000
                      </li>
                      <li><label>Minimum spend</label>
                        <p>$6,000
                      </li>
                    </ul>

                    <h3 class="details-section-title">Peak pricing</h3>
                    <ul>
                      <li><label>Venue hire</label>
                        <p>$6,000
                      </li>
                      <li><label>Minimum spend</label>
                        <p>$6,000
                      </li>
                    </ul>
                    <ul>
                      <li><label>Food packages</label>
                        <p>Sit down from $250 - $300<br /> Standing from $150 - $200
                      </li>
                      <li><label>Beverage packages</label>
                        <p>From $120 - $220<br /> Corkage fee $5 per head
                      </li>
                      <li><label>Elopements / micro weddings </label>
                        <p>Hire price from $2,000
                      </li>
                    </ul>
                    <p><label>Additional hire items available for a fee</label></p>
                    <p>- Clean up the following day<br /> - Another item</p>
                  </div>

                </div> -->

                <div class="facilities-and-amenities">
                    @if (facilities && facilities.length > 0) {
                        <h2 class="details-section-title">Facilities</h2>
                        <div class="facilities-and-amenities-list">
                            <ul [class.show-more-data]="isFacilitiesExpanded">
                                @for (facility of isMobile && !isFacilitiesExpanded ? facilities.slice(0, 7) : facilities; track facility;
                                    let i = $index) {
                                    <li [class.disabled]="!uniqueVenueFacilitiesIds.includes(facility.id)">
                                        <img
                                            [src]="facility.imagePath ? commonConstants.IMAGE_KIT_URL + facility.imagePath + '?tr=w-24,h-24,q-100'  : commonConstants.DEFAULT_NO_IMAGE"
                                            width="24" height="24" alt="{{facility.name}}-icon"
                                            (error)="setDefaultImage($event)" loading="lazy">
                                        {{ facility.name }}
                                    </li>
                                }
                            </ul>
                            @if (isMobile && facilities.length > 7) {
                                <button class="read-more-btn" [class.read-less]="isFacilitiesExpanded"
                                        (click)="toggleFacilitiesAccommodation('facilities')">{{
                                        isFacilitiesExpanded ?
                                            'Show less' : 'Show more'
                                    }}
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.04 6.51953V18.2197" stroke="#7093FF" stroke-miterlimit="10"
                                              stroke-linecap="round"/>
                                        <path d="M8.04004 14.2207L12.04 18.2207L16.04 14.2207" stroke="#7093FF"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            }
                        </div>
                    }

                    @if (accommodation && accommodation.length > 0) {
                        <div class="facilities-and-amenities-list">
                            <h2 class="details-section-title">Accommodation amenities</h2>
                            <ul [class.show-more-desc]="isAccommodationExpanded">
                                @for (accommodationData of isMobile && !isAccommodationExpanded ? accommodation.slice(0, 7) : accommodation;
                                    track accommodationData; let j = $index) {
                                    <li [class.disabled]="!uniqueVenueAccommodationIds.includes(accommodationData.id)">
                                        <img
                                            [src]="accommodationData.imagePath ? commonConstants.IMAGE_KIT_URL + accommodationData.imagePath + '?tr=w-24,h-24,q-100' : commonConstants.DEFAULT_NO_IMAGE"
                                            width="24" height="24" alt="{{accommodationData.name}}-icon"
                                            (error)="setDefaultImage($event)" loading="lazy">
                                        {{ accommodationData.name }}
                                    </li>
                                }
                            </ul>
                            @if (isMobile && accommodation.length > 7) {
                                <button class="read-more-btn" [class.read-less]="isAccommodationExpanded"
                                        (click)="toggleFacilitiesAccommodation('accommodation')">{{
                                        isAccommodationExpanded ?
                                            'Show less' : 'Show more'
                                    }}
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.04 6.51953V18.2197" stroke="#7093FF" stroke-miterlimit="10"
                                              stroke-linecap="round"/>
                                        <path d="M8.04004 14.2207L12.04 18.2207L16.04 14.2207" stroke="#7093FF"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            }
                        </div>
                    }
                </div>

                @if (venueDetails?.venue_chit_chats?.length > 0) {
                    <div class="chit-chat-wrap">
                        <h2 class="details-section-title">Chit Chat</h2>
                        <div class="chit-chat-box">
                            <ul>
                                @for (item of venueDetails?.venue_chit_chats; track $index) {
                                    <li>
                                        <div class="chit-chat-item">
                                            {{ item.question }}
                                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1.5844 19.0909C7.00741 18 13.2932 5.9091 15.7582 0C20.0719 0 26.9739 2.72728 20.0719 13.6364C11.4444 27.2728 -5.19436 20.4546 1.5844 19.0909Z"
                                                    fill="#272338"/>
                                            </svg>
                                        </div>
                                    </li>
                                    <li class="chit-chat-send">
                                        <div class="chit-chat-item">
                                            {{ item.answer }}
                                            <svg width="15" height="11" viewBox="0 0 15 11" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M0 8.78988C9.15243 11.5679 17.6078 7.86142 11.7702 6.89493C8.80359 6.40376 5.62448 3.68274 2.75223 0.300049C2.65656 3.44198 1.65503 6.35603 0 8.78988Z"
                                                      fill="#EFB7A6"/>
                                                <path
                                                    d="M3.05984 0.496094C5.9321 3.87879 9.11121 6.5998 12.0778 7.09097C17.9154 8.05746 9.46005 11.764 0.307617 8.98593"
                                                    stroke="black" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        }
                            </ul>
                        </div>
                    </div>
                }

                @if (venueDetails?.ownerDescription) {
                    <div class="details-content-box">
                        <h2 class="details-section-title">More about the venue team</h2>
                        <div class="details-content-desc" #ownerDescContainer
                             [class.show-more-desc]="isOwnerDescExpanded"
                             [innerHTML]="venueDetails?.ownerDescription | safeHtml"></div>
                        @if (isMoreOwnerDesc) {
                            <button class="read-more-btn" [class.read-less]="isOwnerDescExpanded"
                                    (click)="toggleOwnerContent()">{{
                                    isOwnerDescExpanded ? 'Tell me less' :
                                        'Tell me more'
                                }}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.04 6.51953V18.2197" stroke="#7093FF" stroke-miterlimit="10"
                                          stroke-linecap="round"/>
                                    <path d="M8.04004 14.2207L12.04 18.2207L16.04 14.2207" stroke="#7093FF"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                            </button>
                        }
                    </div>
                }
                @if (mapUrl) {
                    <div class="location-and-accommodation">
                        <div class="accommodation-header">
                            <h2 class="details-section-title">Location and nearby accommodation</h2>
                            <div class="accommodation-header-action">
                                <!-- Not in current Phase -->
                                <!-- <button type="button" class="secondary-btn">More about region <img
                                    src="assets/images/external-link-icon.svg" width="20" height="20" alt="external-link-icon"></button> -->
                                <button (click)="toggeleMap(true)"
                                   class="secondary-btn">View accommodation map</button>
                            </div>
                        </div>

                        <!-- Not in current Phase -->
                        <!-- <div class="location-temp-box">
                          <div class="location-temp-info">
                            <img src="assets/images/temp-icon.svg" width="74" height="74" alt="temp-icon">
                            <div class="location-temp">15–27°C</div>
                            <p>Avg. daily temp range</p>
                          </div>

                          <div class="location-climate">
                            <label>Climate</label>
                            <p>Relatively warm year-round. Summer is hottest and the peak travel time.</p>
                          </div>
                        </div> -->
                    </div>

                    <div class="details-map-box" id="stay22-container">
                        <iframe [src]="mapUrl" id="stay22-widget" width="100%" height="702" frameborder="0"></iframe>
                    </div>
                }

                @if (venueDetails?.blog_venues && venueDetails?.blog_venues?.length > 0) {
                    <div class="details-featured-in blogs-list">
                        <h2 class="details-section-title">Featured in</h2>
                        <div class="card-list">
                            @for (blog_venue of venueDetails?.blog_venues; track blog_venue; let k = $index) {
                                <div class="card-main" [class.d-none]="!isFeaturedDataExpanded && k > 1">
                                    <div class="slider-custom">
                                        <a [routerLink]=" ['/'+ routesConstants.BLOG + '/'+ blog_venue?.blogs?.blog_categories[0].slug + '/'+ blog_venue?.blogs?.slug]"
                                           class="image-box">
                                            <img
                                                [src]="blog_venue?.blogs?.featureImagePath ? commonConstants.IMAGE_KIT_URL + blog_venue?.blogs?.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                                width="256" height="250" alt="venue-image"
                                                (error)="setDefaultImage($event)" appSetImageDimensions
                                                loading="lazy">
                                        </a>
                                    </div>
                                    <a [routerLink]=" ['/'+ routesConstants.BLOG + '/'+ blog_venue?.blogs?.blog_categories[0].slug + '/'+ blog_venue?.blogs?.slug]"
                                       class="card-details">
                                        @if (blog_venue?.blogs?.blog_categories && blog_venue?.blogs?.blog_categories.length > 0) {
                                            <span class="card-label">
                {{ blog_venue?.blogs?.blog_categories[0].name }}
              </span>
                                        }
                                        <span class="card-title-inner">{{ blog_venue?.blogs?.title }}</span>
                                        <span class="card-dec" [innerHTML]="blog_venue?.blogs?.excerpt"></span>
                                        <!-- Not in current Phase -->
                                        <!-- <div class="card-category">
                                          <button type="button" class="secondary-btn">Country wedding 🌾</button>
                                          <button type="button" class="secondary-btn">Rural 🚜</button>
                                        </div> -->
                                    </a>
                                </div>
                            }
                        </div>
                        @if (venueDetails?.blog_venues.length > 2) {
                            <button class="read-more-btn" [class.read-less]="isFeaturedDataExpanded"
                                    (click)="toggleFeaturedData()">
                                {{
                                    isFeaturedDataExpanded ?
                                        'Show me less' : 'Show me more'
                                }}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.04 6.51953V18.2197" stroke="#7093FF" stroke-miterlimit="10"
                                          stroke-linecap="round"/>
                                    <path d="M8.04004 14.2207L12.04 18.2207L16.04 14.2207" stroke="#7093FF"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                            </button>
                        }
                    </div>
                }
            </div>


            <div class="details-right-panel">
                <div class="details-quick-info">
                    <div class="wedshed-approved">
                        <img [src]="imagePathConstants.PLANNING_VENUE_VENDOR + '/wedshed-shield.svg'" width="54"
                             height="63"
                             alt="wedshed-shield">
                        <p>This venue is Wedshed approved
                            <span class="info-tooltip">
              <img src="assets/images/info-icon.svg" width="14" height="15" alt="info-icon">
              <span class="info-tooltip-box">This venue host is an official partner of Wedshed. Their venue information
                is up-to-date.</span>
            </span>
                        </p>
                    </div>
                    <!-- Not in current Phase -->
                    <!-- <div class="pricing-info">
                      <i class="price-icon">
                        <img src="assets/images/price-icon.svg" width="56" height="52" alt="price-icon">
                      </i>
                      <p>Pricing available when you <strong (click)="openVerticallyCentered(signup, 'auth-modal')">sign up</strong>
                      </p>
                      <div class="pricing-from" style="display:none;">
                        <label>From</label>
                        $35,000
                      </div>
                    </div> -->
                    @if (venueDetails?.venue_types && venueDetails?.venue_types.length > 0) {
                        <div class="details-category-list">
                            @for (type of venueDetails.venue_types; track type; ) {
                                <label>{{ type?.name }}</label>
                            }
                        </div>
                    }

                    <div class="details-features-info">
                        <ul>
                            <li>
                                <img [src]="imagePathConstants.PLANNING_VENUE_VENDOR + '/seat-icon.svg'" width="52"
                                     height="52"
                                     alt="seat-icon">
                                <p>
                                    <label>SEATED</label>
                                    {{ venueDetails?.seatingCapacity ?? '-' }}
                                </p>
                            </li>
                            <li>
                                <img [src]="imagePathConstants.PLANNING_VENUE_VENDOR + '/standing-icon.svg'" width="52"
                                     height="52"
                                     alt="standing-icon">
                                <p>
                                    <label>STANDING</label>
                                    {{ venueDetails?.standingCapacity ?? '-' }}
                                </p>
                            </li>
                            <li>
                                <img [src]="imagePathConstants.PLANNING_VENUE_VENDOR + '/sleeps-icon.svg'" width="52"
                                     height="52"
                                     alt="sleeps-icon">
                                <p>
                                    <label>SLEEPS</label>
                                    {{ venueDetails?.sleepingCapacity ?? '-' }}
                                </p>
                            </li>
                            <li>
                                <img [src]="imagePathConstants.PLANNING_VENUE_VENDOR + '/curfew-icon.svg'" width="52"
                                     height="52"
                                     alt="curfew-icon">
                                <p>
                                    <label>CURFEW</label>
                                    {{ venueDetails?.curfewTiming ? getFormattedTime(venueDetails?.curfewTiming) : '--:--' }}
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="quick-info-action">
                        <button type="button" class="primary-btn" (click)="openEnquireVenueModal('venue-enquire')">
                            Enquire
                            with venue
                        </button>
                        <p>This is an enquiry, you won’t be charged.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--======================= Vendors who service this venue HTML Start =======================-->
    @if (vendorList && vendorList.length > 0) {
        <section class="vendors-who-service">
            <div class="container">
                <div class="section-header">
                    <h2 class="section-semi-title">Vendors who service this venue</h2>
                    <a type="button" [routerLink]=" ['/'+ routesConstants.VENDOR_NAVIGATION]"
                       [state]="{ region: {name: venueDetails?.regions?.name, id: venueDetails?.regions?.id} }"
                       class="secondary-btn">View all local
                        vendors
                        <img src="assets/images/arrow-right.svg" width="21" height="20" alt="arrow-right-icon">
                    </a>
                </div>
                <div class="card-list">
                    @for (vendor of vendorList; track vendor; let vendorIndex = $index) {
                        <div class="card-main">
                            <div class="slider-custom">
                                <!-- Not in current Phase -->
                                <!-- <label class="local-label">Local</label>
                                <button type="button" class="heart-icon">
                                  <img src="assets/images/heart-icon-solid.svg" width="26" height="26" alt="Heart Icon" title="favorite">
                                </button> -->
                                @if (vendor.vendor_images && vendor.vendor_images.length > 0) {
                                    <div class="slider-inner">
                                        <div class="splide box-slider">
                                            <div class="splide__track">
                                                <ul class="splide__list">
                                                    @for (image of vendor.vendor_images; track image; let j = $index) {
                                                        <li class="splide__slide">
                                                            <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ vendor.slug]"
                                                               class="image-box">
                                                                <img
                                                                    [src]="image.imagePath ? commonConstants.IMAGE_KIT_URL + image.imagePath : commonConstants.DEFAULT_NO_IMAGE"
                                                                    width="256" height="250" alt={{vendor?.serviceName}}
                                                                    (error)="setDefaultImage($event)"
                                                                    appSetImageDimensions loading="lazy">
                                                            </a>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                } @else {
                                    <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ vendor.slug]"
                                       class="image-box">
                                        <img src="{{commonConstants.DEFAULT_NO_IMAGE}}" width="256" height="250"
                                             alt="image-not-found"
                                             loading="lazy">
                                    </a>
                                }
                            </div>
                            <a [routerLink]="['/'+ routesConstants.VENDOR_NAVIGATION + '/'+ vendor.slug]"
                               class="card-details">
                                <div class="card-title">
                                    <h3 class="title">{{ vendor?.serviceName }}</h3>
                                    <!-- Not in current Phase -->
                                    <!-- <div class="right-side-rating">
                                      <span class="rating">4.5</span>
                                      <img src="assets/images/star-icon.svg" width="21" height="20" alt="Star Icon">
                                    </div> -->
                                </div>
                                @if (vendor?.regions || vendor?.states) {
                                    <span class="address">
            {{ vendor?.regions?.name }},
                                        {{ vendor?.states?.name }}
          </span>
                                }

                                @if (vendor?.vendor_types && vendor?.vendor_types.length > 0) {
                                    <div class="card-category">
                                        @for (type of vendor.vendor_types.slice(0,
                                            commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT); track type; let k = $index) {
                                            <button type="button" class="secondary-btn"
                                                    [ngbTooltip]="type?.name">{{ type?.name }}
                                            </button>
                                        }
                                        @if ((vendor?.vendor_types.length - commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT) > 0) {
                                            <div class="care-more-category">+{{
                                                    vendor?.vendor_types.length -
                                                    commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT
                                                }}

                                                <div class="care-more-category-box">
                                                    @for (remainingType of
                                                        vendor.vendor_types.slice(commonConstants.DEFAULT_SHOW_VENDOR_TYPE_COUNT); track
                                                        remainingType; ) {
                                                        <button type="button"
                                                                class="secondary-btn">{{ remainingType?.name }}
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </a>
                        </div>
                    }
                </div>
            </div>
        </section>
    }
    <!--======================= Vendors who service this venue End =======================-->

        <!--Quick Action Box-->
    <section class="quick-action-wrap">
        <div class="container">
            <div class="quick-action-item">
                <h2>Procrastinate your planning by browsing some inspiration. ✨</h2>
                <a type="button" [routerLink]="['/'+ routesConstants.WEDSPIRATION]" class="secondary-btn">
                    Browse Advice & Ideas
                    <img src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="button-right-icon">
                </a>
            </div>
            <div class="quick-action-item">
                <h2>Excited and in the mood to explore destinations? 🌏</h2>
                <a type="button" [routerLink]="['/'+ routesConstants.VENUE_NAVIGATION]" class="secondary-btn">
                    Check out regions
                    <img src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="button-right-icon">
                </a>
            </div>

            <div class="quick-action-item">
                <h2>Are you a vendor?<br/> Hi there. 👋</h2>
                <div class="quick-action-btn">
                    <a type="button" [routerLink]="['/'+ routesConstants.SIGN_UP_NAVIGATION]" class="secondary-btn">
                        Sign up
                        <img src="assets/images/arrow-right-icon.svg" width="13" height="10" alt="button-right-icon">
                    </a>
                    <a type="button" [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_VENUES_NAVIGATION]"
                       class="secondary-btn plain-btn">How does it
                        work?</a>
                </div>
            </div>
        </div>
    </section>

    <!--======================= Sign-up and Log-in Section HTML Start =======================-->
    <app-venue-vendor-footer></app-venue-vendor-footer>
    <!--======================= Sign-up and Log-in Section HTML End =======================-->
}
<!-- Start Sign up Modal -->
<!-- <ng-template #signup let-modal>
  <div class="modal-header">
    <h4 class="modal-title"><i class="modal-title-icon"><img src="assets/images/cursor-click-icon.png" width="72"
          height="51" alt="cursor-click-icon"></i>Getting married? Sign up to do more</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')" title="Close">
      <img src="assets/images/close-icon.svg" width="13" height="13" alt="close-icon">
    </button>
  </div>

  <div class="modal-body">
    <form>
      <div class="form-group">
        <label>Your first name</label>
        <input class="form-control" type="email" placeholder="Enter first name">
      </div>
      <div class="form-group auth-mob-no">
        <label>Mobile number <small>Used only for sign up</small></label>
        <input class="form-control" type="email" placeholder="000 000 000">
      </div>

      <div class="form-group">
        <label>Email address</label>
        <input class="form-control" type="email" placeholder="Enter email">
      </div>

      <div class="form-group">
        <label>Password</label>
        <input class="form-control" type="password" placeholder="Enter password">
      </div>

      <div class="form-group mb-0">
        <label>Confirm password</label>
        <input class="form-control" type="password" placeholder="Re-enter password">
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="primary-btn" disabled>Sign up</button>
    <button type="button" class="secondary-btn" (click)="modal.close('Close click')">Cancel</button>
    <div class="have-account-link">Already have an account? <strong
        (click)="openVerticallyCentered(signin, 'auth-modal')">Sign in</strong></div>
  </div>
</ng-template> -->
<!-- END Sign up Modal -->

<!-- Start Sign in Modal -->
<!-- <ng-template #signin let-modal>
  <div class="modal-header">
    <h4 class="modal-title"><i class="modal-title-icon"><img src="assets/images/cursor-click-icon.png" width="72"
          height="51" alt="cursor-click-icon"></i>Getting married? Sign in to do more</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')" title="Close">
      <img src="assets/images/close-icon.svg" width="13" height="13" alt="close-icon">
    </button>
  </div>

  <div class="modal-body">
    <form>
      <div class="form-group">
        <label>Email address</label>
        <input class="form-control" type="email" placeholder="Enter email">
      </div>
      <div class="form-group">
        <label>Password</label>
        <input class="form-control" type="password" placeholder="Enter password">
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="primary-btn">Sign in</button>
    <button type="button" class="secondary-btn" (click)="modal.close('Close click')">Cancel</button>
    <div class="have-account-link">Don’t have an account? <strong>Sign up</strong></div>
  </div>
</ng-template> -->
<!-- END Sign in Modal -->
